/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { Flex } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { Link } from 'gatsby'
import {
  PrismicJobsPageDataType,
  PrismicJobPostDataType,
  PrismicImageType,
} from 'root/graphql-types'
import { BodyLarge10Cols, H6Fit } from 'src/components/atoms/text'
import { ml, sm } from 'src/gatsby-plugin-theme-ui/utils'
import { ResponsiveAsset } from 'src/components/organisms'
import { ImageProp } from 'src/utils/gatsby'
import { Grid, colSx } from 'src/components/atoms/layout'
import linkResolver from 'src/utils/linkResolver'
import { motion } from 'framer-motion'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
  OFFSET_CARDS_Y,
  OFFSET_Y,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'
import ComponentWithGifCursor from 'src/components/molecules/ComponentWithGifCursor'
import { replaceHtml } from 'src/utils/htmlReactParser'
import FullViewportHeadline from 'src/components/molecules/FullViewportHeadline'

type JobsPageProps = {
  pageContext: PrismicJobsPageDataType & {
    jobsVideoMobile: any
    jobsVideoDesktop: any
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
  }
  path: string
}

const JobsPage: FC<JobsPageProps> = ({ pageContext, path }) => {
  const offsetCard = useResponsiveValue(sm(OFFSET_Y, OFFSET_CARDS_Y))
  const [forceRedrawKey, setForceRedrawKey] = useState('jobspage_not_mounted')
  const staggerAfterCard = useResponsiveValue(
    sm(
      DELAY_AFTER_SHUTTER + STAGGER_TIME * 2,
      DELAY_AFTER_SHUTTER + STAGGER_TIME * 4
    )
  )

  useEffect(() => setForceRedrawKey('jobspage_mounted'), [])

  const {
    jobs_image_desktop,
    jobs_image_mobile,
    jobsVideoDesktop,
    jobsVideoMobile,
    job_posts,
    intro_rich_text,
  } = pageContext

  setActiveLink('jobs')
  const hasJobPosts = !_.isEmpty(job_posts)

  return (
    <div key={forceRedrawKey}>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <FullViewportHeadline headline={pageContext?.title_rich_text} />
      </motion.div>
      <motion.div
        {...yOpacityProps({
          delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 2,
          initialY: offsetCard,
        })}
      >
        <ResponsiveAsset
          imageDesktop={jobs_image_desktop as ImageProp}
          imageMobile={jobs_image_mobile as ImageProp}
          videoDesktop={jobsVideoDesktop}
          videoMobile={jobsVideoMobile}
        />
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <BodyLarge10Cols sx={{ my: ml('l', 'xl') }}>
          {replaceHtml(intro_rich_text?.html || '', 'p', BodyLarge10Cols)}
        </BodyLarge10Cols>
      </motion.div>

      <motion.div {...yOpacityProps({ delay: staggerAfterCard })}>
        <div
          sx={{
            borderTopStyle: 'solid',
            borderTopWidth: hasJobPosts ? '1px' : '0px',
            borderTopColor: 'text',
            mb: ml('l', 'xxl'),
          }}
        >
          {_.map(job_posts, (obj, key) => {
            const data = obj?.job_post?.document?.data as PrismicJobPostDataType
            if (!data) return null

            const { post_title, job_location, job_type, cursor_image } = data

            const postTitleHtml = replaceHtml(
              post_title?.html || '',
              'p',
              'div'
            )

            return (
              <ComponentWithGifCursor
                cursorImage={{
                  src: cursor_image?.fluid?.src || '',
                  width: cursor_image?.dimensions?.width || 264,
                  height: cursor_image?.dimensions?.height || 264,
                }}
                component={
                  <Link
                    key={key}
                    to={linkResolver(obj?.job_post?.document)}
                    sx={{ textDecoration: 'none' }}
                  >
                    <div
                      sx={{
                        py: 'l',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'text',
                        cursor: 'none',
                      }}
                    >
                      <Grid
                        columns={sm(1, 12)}
                        gap={['s', 's', 's', 'lGridGap', 'xlGridGap']}
                        sx={{ zIndex: 2000, position: 'relative' }}
                      >
                        <Flex
                          sx={{
                            ...colSx(sm(1, 1), sm(13, 7)),
                            alignItems: 'center',
                          }}
                        >
                          <H6Fit>{postTitleHtml}</H6Fit>
                        </Flex>
                        <Flex
                          sx={{
                            ...colSx(sm(1, 7), sm(13, 10)),
                            display: sm('none', 'flex'),
                            alignItems: 'center',
                          }}
                        >
                          <H6Fit>{job_type}</H6Fit>
                        </Flex>
                        <Flex
                          sx={{
                            ...colSx(sm(1, 10), 13),
                            display: sm('none', 'flex'),
                            alignItems: 'center',
                          }}
                        >
                          <H6Fit>{job_location}</H6Fit>
                        </Flex>
                      </Grid>
                    </div>
                  </Link>
                }
              />
            )
          })}
        </div>
      </motion.div>
    </div>
  )
}

export default JobsPage
